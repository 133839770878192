<template>
  <div class="swiper-container-wrap">
    <div class="magnifier-box swiper-container" :class="vertical?'vertical':''" :ref="id" @mousemove="mousemove" @mouseover="mouseover" @mouseleave="mouseleave">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in gallerySlide" :key="index">
          <img :src="item" alt="">
        </div>
      </div>
      <div class="mouse-cover" ref="mouseCover"></div>
    </div>
    <div class="swiper-container gallery-thumbs">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in gallerySlide" :key="index">
          <img :src="item" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Swiper from "swiper/swiper-bundle.min";
  import 'swiper/swiper-bundle.min.css';
  export default {
    props: {
      scale: {
        type: Number,
        default: 2.5
      },
      gallerySlide: {
        type: Array,
        required: true
      },
      scroll: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        id: null,
        cover: null,
        imgbox: null,
        imgwrap: null,
        img: null,
        canvas: null,
        ctx: null,
        rectTimesX: 0,
        rectTimesY: 0,
        imgTimesX: 0,
        imgTimesY: 0,
        init: false,
        step: 0,
        bigStep: 0,
        vertical: false,
        galleryTop: null,
        galleryThumbs: null,
        slideActiveIndex: 0,
        initPicZoom: false
      }
    },
    created() {
      var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      var maxPos = $chars.length;
      var str = '';
      for (let i = 0; i < 10; i++) {
        str += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      this.id = str;
      this.$bus.$on('init', () => {
        this.swiper();
      })
    },
    // 监听图片切换时重新初始化
    watch: {
      slideActiveIndex(){
        if(this.initPicZoom) this.initTime();
      }
    },
    mounted() {
      this.windowResize();
      window.addEventListener("resize", this.windowResize, false);
    },
    methods: {
      initTime() {
        this.init = false;
        let box = this.$refs[this.id];
        this.imgbox = box;
        this.cover = this.$refs.mouseCover;
        this.cover.style.width = (this.imgbox.offsetWidth / this.scale) + 'px';
        this.cover.style.height = (this.imgbox.offsetHeight / this.scale) + 'px';
        this.cover.style.left = '-100%'
        this.cover.style.top = '-100%'
        this.imgwrap = this.imgbox;

        this.img = new Image()
        this.img.src = this.gallerySlide[this.slideActiveIndex]
        console.log("src",this.img.src)
        this.img.onload = () => {
          this.rectTimesX = (this.imgbox.offsetWidth / this.scale) / this.imgwrap.offsetWidth,
            this.rectTimesY = (this.imgbox.offsetHeight / this.scale) / this.imgwrap.offsetHeight
            //this.img.width图片真实宽度
          this.imgTimesX = this.img.width / this.imgwrap.offsetWidth,
            this.imgTimesY = this.img.height / this.imgwrap.offsetHeight
          this.vertical = this.img.width < this.img.height
          this.init = true
        }
        
        if (this.canvas) {
          this.canvas.parentNode.removeChild(this.canvas);
          this.canvas = null
        }
        
        this.canvas = document.createElement('canvas')
        this.canvas.className = 'mouse-cover-canvas'
        this.canvas.style.position = 'absolute'
        this.canvas.style.left = this.imgbox.offsetLeft + this.imgbox.offsetWidth + 10 + 'px'
        this.canvas.style.top = this.imgbox.offsetTop + 'px'
        // this.canvas.style.border = '1px solid #0f0'
        this.canvas.style.boxShadow = '0px 0px 6px 0px rgba(0, 0, 0, 0.2)'
        this.canvas.style.zIndex = '99999'
        this.canvas.height = this.imgbox.offsetHeight
        this.canvas.width = this.imgbox.offsetWidth
        this.canvas.style.display = 'none'
        this.imgbox.parentElement.append(this.canvas)
        this.ctx = this.canvas.getContext("2d");
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        
      },
      mousemove(e) {
        if (!this.init) {
          return false
        }
        let _this = this
        //获取实际的offset
        function offset(curEle) {
          var totalLeft = null,
            totalTop = null,
            par = curEle.offsetParent;
          //首先加自己本身的左偏移和上偏移
          totalLeft += curEle.offsetLeft;
          totalTop += curEle.offsetTop
          //只要没有找到body，我们就把父级参照物的边框和偏移也进行累加
          while (par) {
            if (navigator.userAgent.indexOf("MSIE 8.0") === -1) {
              //累加父级参照物的边框
              totalLeft += par.clientLeft;
              totalTop += par.clientTop
            }

            //累加父级参照物本身的偏移
            totalLeft += par.offsetLeft;
            totalTop += par.offsetTop
            par = par.offsetParent;
          }

          return {
            left: totalLeft,
            top: totalTop
          }
        }

        function getXY(eve) {
          return {
            x: eve.clientX - (_this.cover.offsetWidth / 2),
            y: eve.clientY - (_this.cover.offsetHeight / 2)
          };
        }
        let oEvent = e || event;
        let pos = getXY(oEvent);
        let imgwrap = offset(this.imgwrap)
        let range = {
          minX: imgwrap.left,
          maxX: imgwrap.left + this.imgwrap.offsetWidth - this.cover.offsetWidth,
          minY: imgwrap.top - document.documentElement.scrollTop,
          maxY: imgwrap.top - document.documentElement.scrollTop + this.imgwrap.offsetHeight - this.cover.offsetHeight,
        }
        if (pos.x > range.maxX) {
          pos.x = range.maxX
        }
        if (pos.x < range.minX) {
          pos.x = range.minX
        }
        if (pos.y > range.maxY) {
          pos.y = range.maxY
        }
        if (pos.y < range.minY) {
          pos.y = range.minY
        }
        this.cover.style.left = pos.x + 'px'
        this.cover.style.top = pos.y + 'px'
        this.ctx.clearRect(0, 0, this.imgwrap.offsetWidth, this.imgwrap.offsetHeight);
        let startX = pos.x - (imgwrap.left - document.documentElement.scrollLeft),
            startY = pos.y - (imgwrap.top - document.documentElement.scrollTop);
        this.ctx.drawImage(this.img, startX * this.imgTimesX, startY * this.imgTimesY, this.img.width * this.rectTimesX,
          this.img.height * this.rectTimesY, 0, 0, this.imgbox.offsetWidth, this.imgbox.offsetHeight);

      },
      mouseover(e) {
        if (!this.init) {
          return false
        }
        e = e || event
        if (!this.scroll) {
          e.currentTarget.addEventListener("mousewheel", function(ev) {
            ev.preventDefault();
          }, false);

          e.currentTarget.addEventListener("DOMMouseScroll", function(ev) {
            ev.preventDefault();
          }, false);
        }

        this.cover.style.display = 'block'
        this.canvas.style.display = 'block'
      },
      mouseleave() {
        if (!this.init) {
          return false
        }
        this.cover.style.display = 'none'
        this.canvas.style.display = 'none'
      },
      swiper() {
        this.galleryThumbs = new Swiper('.gallery-thumbs', {
          spaceBetween: 10,
          slidesPerView: 4,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          observer:true,
          observeParents:true
        });
        this.galleryTop = new Swiper('.magnifier-box', {
          spaceBetween: 10,
          loop: true,
          pauseOnMouseEnter: true,
          observer:true,
          observeParents:true,
          thumbs: {
            swiper: this.galleryThumbs
          }
        });
        this.monitorSlide();
        this.monitorImagesReady();
      },
      // 监听当前激活的图片序号
      monitorSlide(){
        let that = this;
        this.galleryTop.on("slideChange", function(){
          that.slideActiveIndex = this.realIndex;
        })
      },
      // 监听图片是否已经加载完毕
      monitorImagesReady(){
        this.galleryTop.on("imagesReady", () => {
          // console.log("图片加载完成了")
          if(this.initPicZoom) this.initTime();
        })
      },
      // 监听窗口尺寸变化
      windowResize(){
        let pageW = document.documentElement.clientWidth || document.body.clientWidth;
        this.initPicZoom =  pageW > 974 ? true : false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .magnifier-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
    }

    .mouse-cover {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: move;
      z-index: 1000;
    }

    .mouse-cover-canvas {
      position: fixed;
      left: 100%;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &.vertical {
      img {
        height: 100%;
        width: auto
      }
    }
    
  }
  .gallery-thumbs{
    margin-top: 20px;
    width: 100%;
    .swiper-slide{
      width: initial;
      cursor: pointer;
    }
  }
</style>
